import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500">
        Try guess da word in 6 tries. Aftah, da color of da tiles
        stay changing fo show how close you stay.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="B" status="correct" />
        <Cell value="U" />
        <Cell value="L" />
        <Cell value="A" />
        <Cell value="I" />
      </div>
      <p className="text-sm text-gray-500">
        The letter B is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="N" />
        <Cell value="O" status="present" />
        <Cell value="C" />
        <Cell value="A" />
        <Cell value="N" />
      </div>
      <p className="text-sm text-gray-500">
        The letter O is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="N" />
        <Cell value="O" />
        <Cell value="A" />
        <Cell value="C" status="absent" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500">
        The letter C is not in the word in any spot.
      </p>
    </BaseModal>
  )
}
