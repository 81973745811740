export const WORDS = [
  'ilima',
 'killa',
 'bulai',
 'shoyu',
 'stink',
 'relax',
 'manoa',
 'stick',
 'mauna',
 'lehua',
 'uncle',
 'mauka',
 'habut',
 'makea',
 'aunty',
 'ponoi',
 'irahz',
 'winna',
 'lanai',
 'benjo',
 'oojee',
 'makua',
 'malie',
 'nocan',
 'heiau',
 'ekolu',
 'shaka',
 'sista',
 'rajah',
 'keawe',
 'pocho',
 'junks',
 'spock',
 'kukui',
 'irras',
 'tanks',
 'kauai',
 'grind',
 'mines',
 'irraz',
 'opihi',
 'yahno',
 'aikea',
 'blala',
 'crack',
 'lidat',
 'primo',
 'keiki',
 'pound',
 'okole',
 'oddah',
 'mento',
 'ohana',
 'makai',
 'haole',
 'jamup',
 'kanak',
 'kokua',
 'manao',
 'hanai',
 'shoot',
 'boroz',
 'scrap',
 'broke',
 'awbus',
 'mochi',
 'niele',
 'bento',
 'ifcan',
 'brada',
 'local',
 'lihue',
 'bocha',
 'aiyah',
 'nevah',
 'loihi',
 'pilau',
 'maoli',
 'pupus',
 'hapai',
 'chang',
 'keoni',
 'noact',
 'solid',
 'skosh',
 'olelo',
 'choke',
 'aloha']